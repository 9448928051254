import React from 'react';
import { Layout } from 'antd';

import Sidebar from '../Sidebar/index';
import HorizontalDark from '../Topbar/HorizontalDark';

import App from 'routes/index';
import { useRouteMatch } from 'react-router-dom';

const {
  Content,
  Footer
} = Layout;

const MainApp = () => {
  const match = useRouteMatch();

  return (
    <Layout className='gx-app-layout'>
      <Sidebar />
      <Layout>
        <HorizontalDark />
        <Content className='gx-layout-content gx-container-wrap'>
          <App match={match} />
          <Footer>
            <div className='gx-layout-footer-content'>
              &copy; Создано <a href='https://logiq.one' target='_blank' rel="noopener noreferrer">logiq.one</a> {new Date().getFullYear()}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};

export default MainApp;

