import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const HorizontalNav = () => {
  const pathname = useSelector(({settings}) => settings.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <Menu
      style={{ width: '100%' }}
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode='horizontal'
    >
      <Menu.Item key='1'>
        <Link style={{color: 'white'}} to='/'>
          <i className='icon icon-home'/>
        </Link>
      </Menu.Item>

      <Menu.Item key='2'>
        <Link style={{color: 'white'}} to='/'>
          <i className='icon icon-alert'/>Объявления
        </Link>
      </Menu.Item>

      <Menu.Item key='3'>
        <Link style={{color: 'white'}} to='/'>
          <i className='icon icon-feedback'/>Опросы и голосования
        </Link>
      </Menu.Item>

      <Menu.Item key='4'>
        <Link style={{color: 'white'}} to='/'>
          <i className='icon icon-editor'/>Документы
        </Link>
      </Menu.Item>

      <Menu.Item key='5'>
        <Link style={{color: 'white'}} to='/'>
          <i className='icon icon-company'/>УК
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default HorizontalNav;

